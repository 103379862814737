import { FunctionComponent } from 'react';
import { Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { SitePage } from '../app/layout';
import logoMonash from '../app/landing/imgs/logo-umonash.png';
import logoGma from '../app/landing/imgs/logo-GMA.png';
import { i18next } from '@vega/common';

const t = i18next.t;
const Participate: FunctionComponent = () => {
  const theme = useTheme();

  return (
    <SitePage>
      <Box sx={{ marginTop: theme.spacing(7), display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ maxWidth: { md: '80%' }, mx: theme.spacing(4) }}>
          <Typography variant="h4" paragraph fontWeight="bold">
            {t('participate-page.title')}
          </Typography>
          <Typography paragraph>{t('participate-page.description')}</Typography>
          <Box sx={{ py: 2, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            <img src={logoMonash} alt="University of Sydney Logo" height={55} />
            <img src={logoGma} alt="Growing Minds Australia Logo" height={55} />
          </Box>
          <Typography paragraph>
            <a href="https://redcap.link/gmci_c" target="_blank">
              {t('participate-page.Participant Information Statement')}
            </a>
          </Typography>
          <Typography paragraph>{t('participate-page.contact-us')}</Typography>
          <Typography paragraph>
            <strong>{t('general.email')}:</strong> gmci.c@monash.edu
            <br />
            {/* <strong>{t('general.phone')}:</strong> (02)&nbsp;9114&nbsp;4326 */}
          </Typography>
        </Box>
      </Box>
    </SitePage>
  );
};

export default Participate;
